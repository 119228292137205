/**
 * This mixin needs to be included for crux-lookup-component and its subs to work.
 * @mixin crux-lookup-mixin
 * @author anuja.manoharan
 * @version 1.0.0
 */
Lyte.Mixin.register("crux-lookup-mixin", {//No I18n
	/**
	 * lookupInit is called to fetch the module data if not present and then forward to fetch the record data. If the modal needs to render related module info as well, those requests are made here as well.
	 * @author anuja.manoharan
	 * @version 1.0.0
	 * @params comp - the component so that data can be set directly to it
	 * @params modId - since store request to module model with given modId
	 * @params lookup
	 * @params event
	 * @params ddelem
	 * @internal
	 */
	lookupInit : function(comp,modId,lookup,event,ddelem){
		var prom = [this.executeMethod("fetchModuleData", modId)];
		if(this.data.cxPropRelatedId){ //We can eliminate this api call by using lookup-advanced
			this.setData("headerOptions", [{id : this.data.cxPropRelatedId, name : _cruxUtils.getI18n("crm.related.contact.account", this.data.module.plural_label, this.data.cxPropRelatedName)},
				{id : "2", name : _cruxUtils.getI18n("crm.allcontact.show", this.data.module.plural_label)}]);
			this.setData("selectedHeaderValue", this.data.cxPropRelatedId);
			prom.push(this.executeMethod("fetchModuleData", this.data.cxPropRelatedModuleId));
		}
		var _self = this;
		Lyte.resolvePromises(prom).then(function(resp){
			if(comp.$node){
				var mod = resp[0];
				comp.setData("module", mod);
				if(!_self.data.modId){
					_self.setData("modId", mod.id);
				}
				comp.setData("moduleName", mod.module_name);
				comp.setData("displayField", mod.display_field.api_name);
				lookup ? comp.getRecs() : comp.beforeShowFunc(event, ddelem, true);
			}
		})
	},
	/**
	 * constructSearchFilter is called before each search request is triggered to store. This will construct the filters query param.
	 * @author anuja.manoharan
	 * @version 1.0.0
	 * @internal
	 * @param {*} disp - the display value to which the main filter value is applied
	 * @param {*} val - the search value
	 * @param {*} field 
	 * @param {*} searchFormat 
	 * @returns the filters query param value
	 */
	constructSearchFilter : function(disp, val,field,searchFormat){
		/**
		 * user can choose to pass his own fields to be searched by passing cxPropSearchFields
		 */
		if(this.data.cxPropSearchFields && this.data.cxPropSearchFields.length > 0){
			return JSON.stringify(this.constructGroup(this.data.cxPropSearchFields, val));
		}
		var comparator = field && field.crypt !== null ? "equals" : searchFormat ? "starts_with" : "contains";
		var result = searchFormat ? "("+disp+":"+comparator+":"+val.replace(/[(]/g, "\\(").replace(/[)]/g, "\\)")+")" : {comparator : comparator, field : {api_name : disp}, value : val};
		/**
		 * for the case of Products, product code needs to be searched along with product name
		 */
		if((this.data.modulenameUicomp === "Products" || this.data.moduleName === "Products") && this.data.module.fields){
			var _field = this.data.module.fields.filterBy({api_name : "Product_Code"});
			if(_field.length && _field[0].visible){
				result = searchFormat ? "("+result+"or(Product_Code:starts_with:"+val.replace(/[(]/g, "\\(").replace(/[)]/g, "\\)")+"))" : {group_operator : "or", group : [result, {comparator : "contains", field : {api_name : "Product_Code"}, value : val}]};
			}
		}
		if(this.data.header){
			for(var i=0; i<this.data.header.length; i++){
				if(this.data.header[i].api_name == disp){
					continue;
				}
				switch(this.data.header[i].cxTypeMapping){
				case "email":
				case "phone":
					result = searchFormat ? "("+result+"or("+this.data.header[i].api_name+":starts_with:"+val.replace(/[(]/g, "\\(").replace(/[)]/g, "\\)")+"))" : {group_operator : "or", group : [result, {comparator : "contains", field : {api_name : this.data.header[i].api_name}, value : val}]};
					break;
				}
			}
		}
		return searchFormat ? result : JSON.stringify(result);
	},
	constructGroup : function(fields, value){
		if(fields.length > 1){
			return {group_operator : "or", group : [{comparator : "contains", field : {api_name : fields[0]}, value : value}, this.constructGroup(fields.splice(1), value)]};
		}
		return {comparator : "contains", field : {api_name : fields[0]}, value : value};
	}
});
